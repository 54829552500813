// Imports


// Plugins
const plugins = [{
  location: "/Users/michaelsmith/playground/external-techs/node_modules/react-static-plugin-styled-components",
  plugins: [],
  hooks: {}
},
{
  location: "/Users/michaelsmith/playground/external-techs",
  plugins: [],
  hooks: {}
}]

// Export em!
export default plugins