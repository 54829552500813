import React from 'react';
import { Root, Routes } from 'react-static';
import { Link } from '@reach/router';
import styled from 'styled-components';

const Nav = styled.nav`
  background: #83c0fd7d;
  position: absolute;
  color: white;
  background-size: cover;
  z-index: 1;
  right: 0;
`;

import './app.css';

function App() {
  return (
    <Root>
      <Nav>
        <Link to="/">Home</Link>
        <Link to="/blog">Blog</Link>
      </Nav>
      <Routes />
    </Root>
  );
}

export default App;
